module.exports = {
  siteTitle: 'Dekoratiivsed seinapaneelid.', // <title>
  manifestName: 'Timberart',
  manifestShortName: 'Timber', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#000',
  manifestThemeColor: '#000',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'TimberArt OÜ',
  subHeading:
    "Käsitööna valminud puidust dekoratiivsed seinapaneelid.",
  socialLinks: [
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/TimberArt-O%C3%9C-102965325164787',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:timberart.ou@gmail.com',
    },
  ],
};
